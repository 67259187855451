<template>
  <BTableCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :icon="'fad fa-images'"
    :title="'Banner'"
    :total="gorseller.length"
    @handlerAddNew="handlerSelectedImages"
  >
    <template v-slot:body>
      <input
        multiple
        type="file"
        ref="file"
        name="logo"
        id="selectorImagesInput"
        accept="image/jpg,image/jpeg,image/png,image/gif"
        class="selector-images-input"
        @change="handlerOnSumbited"
      />
      <div class="p-1 px-3">
        <b-row v-if="!gorseller.length">
          <b-col>
            <b-alert variant="warning" show class="text-center">
              <h4 class="alert-heading">Bilgi!</h4>
              <p>Henüz Kayıt Bulunmamaktadır.</p>
              <p>Çoklu Resim yüklemeler de bazı resimler görünmeye bilir sayfayı yenileyiniz lütfen.</p>
            </b-alert>
          </b-col>
        </b-row>
        <draggable v-else v-model="gorseller" class="row" tag="div" handle=".item" @end="handlerBannerChange">
          <b-col cols="12" md="3" class="item" v-for="(item, index) in gorseller" :key="index">
            <BannerItem :item="item" />
          </b-col>
        </draggable>
      </div>
    </template>
  </BTableCard>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import BTableCard from '@/components/cards/BTableCard.vue';
import BannerItem from './component/BannerItem.vue';
import draggable from 'vuedraggable';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: {
    draggable,
    BTableCard,
    BannerItem,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.gorseller = computed(() => store.getters.getBanner);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    onMounted(async () => {
      expo.show.value = true;
      await store.dispatch('bannerListele');
      expo.show.value = false;
    });

    expo.handlerSelectedImages = () => {
      const file = document.getElementById('selectorImagesInput');
      file.click();
    };

    expo.handlerOnSumbited = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = context.refs.file.files;

      Object.keys(forms).map(function (key) {
        formData.append('banner', forms[key]);
      });

      let icerik = {};
      langs.forEach((dil) => {
        icerik[dil.lang] = { baslik: '', aciklama: '', url: 'https://' };
      });

      formData.append('icerik', JSON.stringify(icerik));

      await store
        .dispatch('bannerEkle', formData)
        .then((res) => {
          expo.show.value = false;
          context.emit('updated', res.data.data);
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
        });
    };

    expo.handlerBannerChange = () => {
      store
        .dispatch('bannerSiraGuncelle', { props: expo.gorseller.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sira Güncelleme Başarılı', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
